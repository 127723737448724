<template>
  <div class="">
    <div class="d-flex mb-2">
      <span class="page-header-title">Service Request Details</span>
    </div>
    <CCard>
      <CCardBody>
        <button type="button" class="btn btn-outline-info btn-sm" @click="
          (e) => {
            this.$router.go(-1);
          }
        ">
          Back
        </button>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Request No
          </div>
          <div class="col-md-9">
            {{ userObj.request_id }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Name
          </div>
          <div class="col-md-9">
            {{ userObj.name }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Email ID
          </div>
          <div class="col-md-9">
            {{ userObj.email }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Controller Type
          </div>
          <div class="col-md-9">
            {{ userObj.controlType }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            MTB Name
          </div>
          <div class="col-md-9">
            {{ userObj.mtbName }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Machine Model Name
          </div>
          <div class="col-md-9">
            {{ userObj.machine_model_name }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            NC Serial Number
          </div>
          <div class="col-md-9">
            {{ userObj.nCSerialNumber }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Issue Type
          </div>
          <div class="col-md-9">
            {{ userObj.issueType }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Subject
          </div>
          <div class="col-md-9">
            {{ userObj.subject }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Issue Details
          </div>
          <div class="col-md-9">
            {{ userObj.issueDetails }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Company Name
          </div>
          <div class="col-md-9">
            {{ userObj.company_name }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Company Address
          </div>
          <div class="col-md-9">
            {{ userObj.company_address }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Country
          </div>
          <div class="col-md-9">
            {{ userObj.country }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            State
          </div>
          <div class="col-md-9">
            {{ userObj.state }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            City
          </div>
          <div class="col-md-9">
            {{ userObj.city }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Pin Code
          </div>
          <div class="col-md-9">
            {{ userObj.postal_code }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Phone Number
          </div>
          <div class="col-md-9">
            {{ userObj.phone_no }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Region
          </div>
          <div class="col-md-9">
            {{ userObj.region_name }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Comment
          </div>
          <div class="col-md-9">
            {{ userObj.comment }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Status
          </div>
          <div class="col-md-9">
            {{ userObj.status }}
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Attachments
          </div>
          <div class="col-md-9">
            <div class="row text-center align-items-end " v-if="imagesArray.length > 0">
              <div class="col-md-4 " v-for="item in imagesArray" :key="item">
                <div class=" border-none  card-hight">

                  <div class="card-body image-download-body align-bottom">
              
                      <div v-on:click="openMedia(item.photo)" :id="item.photo" v-if="item.photo" 
                        draggable="false" class="border image-border" style="">
                        <img class="image-ascpect-ratio align-bottom" v-bind:src="item.photo" draggable="false"  oncontextmenu="return false;" style="width: 100% !important; background-color:transparent !important; min-height : 10rem !important; max-height: 10rem !important; object-fit:contain !important; "/>

                      </div>

                      <div class="card-text align-bottom">
                    <em class="fa fa-download fa-2x cursor mr-2 mt-2" v-b-tooltip.hover title="Download"
                      v-on:click="downloadAttachemnt(item.photo)"></em>
                  </div>
                    
                    
                   
                  </div>
             
                </div>
                
                  
              </div>

            </div>
            <div v-else>-</div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-3" style="font-size: 16px; font-weight: bold">
            Location
          </div>
          <div class="col-md-9">
            <div style="" class="mt-3" v-if="coOrdinates.lat">
              <GmapMap :zoom="15" map-type-id="terrain" style="min-height: 400px" ref="mapRef" :center="coOrdinates">
                <GmapMarker :position="coOrdinates" v-on:click="openMapInTab(coOrdinates)"/>
              </GmapMap>
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <div>
      <b-modal id="imagepopup" title="Attachment Preview" cancel-title="Cancel" size="md">
        <img v-bind:src="previewImage" class="service-image-class" oncontextmenu="return false;" draggable="false" />
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          </div>
        </template>

      </b-modal>

    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { SERVICE_DETAILS, MEDIA_IMAGE } from "@/store/actions.type";
// import { ImageMagnifier } from "vue-image-magnifier";
// import VueZoomImg from 'vue-zoom-img';


export default {
  name: "ServiceRequestDetails",
  components: { },
  directives: {
      },
  data() {
    return {
      center: {
        lat: 12.9716,
        lng: 77.5946,
      },
      userId: 0,
      coOrdinates: {
        lat: 0,
        lng: 0,
      },
      userObj: {
        name: "",
        id: "",
        request_id: "",
        controlType: "",
        mtbName: "",
        nCSerialNumber: "",
        issueType: "",
        subject: "",
        issueDetails: "",
        attachment: "",
        location: "",
        details: "",
        phone_no: "",
        postal_code: "",
        state: "",
        email: "",
        city: "",
        company_address: "",
        company_name: "",
        country: "",
        machine_model_name: "",
        country_code: "",
        region_name: "",
        status: "",
        comment: "",
      },
      imagesArray: [],
      previewImage: '',
      url: 'https://www.google.com/maps/search/?api=1&query='
    };
  },

  methods: {
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },

    openMedia(image) {
      this.previewImage = image;
      this.$bvModal.show("imagepopup");
    },

    closePreviewModal() {
      this.previewImage = '';
      this.$bvModal.hide("imagepopup");
    },
    openMapInTab(cord){
      let URL = this.url+cord.lat+','+cord.lng;
      window.open(URL, '_blank');
    },

    downloadAttachemnt(attachment) {
      const downloadLink = document.createElement("a");
      const fileName = "ServiceRequest.JPEG";
      downloadLink.href = attachment;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    /**
     * @method getServiceRequestDetails
     * @description get service details
     * @author @Jeevan Poojary
     */

    async getServiceRequestDetails() {
      if (this.userId) {
        this.isServiceDetailsLoading = this.$loading.show();
        await this.$store
          .dispatch(SERVICE_DETAILS, {
            id: this.userId,
          })
          .then((data) => {
            if (data.success) {
              let detailsDataObj = _.get(data, "data.model", []);
              if (detailsDataObj) {
                this.userObj = detailsDataObj;
                this.userObj.id = detailsDataObj.id;
                this.userObj.details = detailsDataObj.details
                  ? detailsDataObj.details
                  : "-";
                this.userObj.issueDetails = detailsDataObj.details
                  ? detailsDataObj.details
                  : "-";
                this.userObj.controlType = detailsDataObj.controller_type
                  ? detailsDataObj.controller_type
                  : "-";
                this.userObj.mtbName = detailsDataObj.mtb_type
                  ? detailsDataObj.mtb_type
                  : "-";
                this.userObj.location = detailsDataObj.location_url
                  ? detailsDataObj.location_url
                  : "-";
                this.coOrdinates.lat = detailsDataObj.latitude
                  ? Number(detailsDataObj.latitude)
                  : "";
                this.coOrdinates.lng = detailsDataObj.longitude
                  ? Number(detailsDataObj.longitude)
                  : "";
                this.userObj.request_id = detailsDataObj.request_id
                  ? detailsDataObj.request_id
                  : "-";
                this.userObj.name = detailsDataObj.name
                  ? detailsDataObj.name
                  : "-";
                this.userObj.nCSerialNumber = detailsDataObj.nc_number
                  ? detailsDataObj.nc_number
                  : "-";
                this.userObj.subject = detailsDataObj.subject
                  ? detailsDataObj.subject
                  : "-";
                this.userObj.issueType = detailsDataObj.issue_type
                  ? detailsDataObj.issue_type
                  : "-";
                this.userObj.city = detailsDataObj.city
                  ? detailsDataObj.city
                  : "-";
                this.userObj.company_address = detailsDataObj.company_address
                  ? detailsDataObj.company_address
                  : "-";
                this.userObj.company_name = detailsDataObj.company_name
                  ? detailsDataObj.company_name
                  : "-";
                this.userObj.machine_model_name =
                  detailsDataObj.machine_model_name
                    ? detailsDataObj.machine_model_name
                    : "-";
                this.userObj.phone_no = detailsDataObj.phone_no
                  ? detailsDataObj.phone_no
                  : "-";
                this.userObj.country_code = detailsDataObj.country_code
                  ? detailsDataObj.country_code
                  : "";
                this.userObj.phone_no =
                  this.userObj.country_code + " " + this.userObj.phone_no;
                this.userObj.country = detailsDataObj.country;
                this.userObj.postal_code = detailsDataObj.postal_code
                  ? detailsDataObj.postal_code
                  : "-";
                this.userObj.region_name = detailsDataObj.region_name
                  ? detailsDataObj.region_name
                  : "-";
                this.userObj.state = detailsDataObj.state
                  ? detailsDataObj.state
                  : "-";
                this.userObj.email = detailsDataObj.email
                  ? detailsDataObj.email
                  : "-";
                this.userObj.status =
                  detailsDataObj.status && detailsDataObj.status == 1
                    ? "Processed"
                    : "Pending";
                this.userObj.comment = detailsDataObj.comment
                  ? detailsDataObj.comment
                  : "-";
                if (this.userObj.country == 0) {
                  this.userObj.country = "India";
                } else if (this.userObj.country == 1) {
                  this.userObj.country = "Sri Lanka";
                } else if (this.userObj.country == 2) {
                  this.userObj.country = "Bangladesh";
                }
                if (detailsDataObj.images.length > 0) {
                  this.imagesArray = detailsDataObj.images;
                }
              }
              this.isServiceDetailsLoading.hide();
            } else {
              this.$toast.removeAll();
              this.$toast.error("ID does not exist. ");
              this.isServiceDetailsLoading.hide();
              this.$router.push({
                name: "servicerequest",
              });
            }
            this.isServiceDetailsLoading.hide();
          });
      }
    },

  },
  async created() {
    // this.userId = _.get(this.$route, "params.id", 0);
    this.userId = this.$store.state.selectedServices || 0;
    this.getServiceRequestDetails();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('set', ['selectedServices', null]);
    next()
  },
};
</script>
<style scoped>
.service-image-class {
  width: 100% !important;
  border-radius: 3px !important;
}

.service-image-class-attchment {
  width: 100% !important;
  height: 250px !important;
  border-radius: 3px !important;
}
.image-border{
 border: 2px solid #d8dbe0 !important;
}
@media (max-width: 435px) {
  .image-border{
    border: 1px solid #d8dbe0 !important;
  }
}
</style>
